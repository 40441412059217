<template>
  <div>
    <Navbar page="Relatório Agentes de tratamento" link="/entregaveis" nameLink="Entregáveis" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">

        <h1 class="text-3xl mb-7">Relatório Agentes de tratamento</h1>
        
        <div class="grid grid-cols-12 gap-6 mb-3">
            <div class="col-span-12 xl:col-span-3 mb-1 mt-1">
              <select v-model="fornecedor" name="fornecedor" id="fornecedor" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                  <option default :value="null">Todos os Agentes de tratamento</option>
                  <option v-for="fornecedor in fornecedores" :key="fornecedor._id" :value="fornecedor._id">{{ fornecedor.nome }}</option>
              </select>
            </div>
            <div class="col-span-12 md:col-span-2 mb-1 mt-2">
              <button @click="start" class="bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
            </div>
            <div class="col-span-12 md:col-span-2 mb-1 mt-2">
              <a :href="`${url_api}/v1/diagnosticos/exportarRelatorioFornecedor/${$store.state.empresa._id}?fornecedor=${fornecedor}&token=${$store.state.token}`" type="button" class="inline-block bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
                Download 
              </a>
            </div>
        </div>
        
        <div v-if="list && list.length" class="flex flex-col mt-4">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="px-3 py-4 text-left text-xs font-medium text-gray-500 tracking-wider">
                          Nome
                        </th>
                        <th scope="col" class="px-3 py-4 text-left text-xs font-medium text-gray-500 tracking-wider">
                          Tipo
                        </th>
                        <th scope="col" class="px-3 py-4 text-left text-xs font-medium text-gray-500 tracking-wider">
                          CNPJ
                        </th>
                        <th scope="col" class="px-3 py-4 text-left text-xs font-medium text-gray-500 tracking-wider">
                          Contrato LGPD
                        </th>
                        <th scope="col" class="px-3 py-4 text-left text-xs font-medium text-gray-500 tracking-wider">
                          Aditivo LGPD
                        </th>
                        <th scope="col" class="px-3 py-4 text-left text-xs font-medium text-gray-500 tracking-wider">
                          Nível de conformidade
                        </th>
                        <th scope="col" class="px-3 py-4 text-left text-xs font-medium text-gray-500 tracking-wider">
                          Data Diagnóstico Finalizado 
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="item in list" :key="item._id">
                        <td class="px-3 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="text-sm text-gray-500">
                              {{ item.fornecedor && item.fornecedor.nome ? item.fornecedor.nome : ''}}
                            </div>
                          </div>
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="text-sm text-gray-500">
                              {{ item.fornecedor && item.fornecedor.agente && item.fornecedor.agente.nome ? item.fornecedor.agente.nome : ''}}
                            </div>
                          </div>
                        </td>
                        <td class="px-3 py-4">
                          <div class="flex items-center">
                            <div class="text-sm_2 text-gray-500">
                              {{ item.fornecedor && item.fornecedor.cnpj ? item.fornecedor.cnpj : ''}}
                            </div>
                          </div>
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="text-sm text-gray-500">
                              {{ item.fornecedor && item.fornecedor.contrato ? 'Sim': 'Não'}}
                            </div>
                          </div>
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="text-sm text-gray-500">
                              {{ item.fornecedor && item.fornecedor.adtivo ? 'Sim': 'Não'}}
                            </div>
                          </div>
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="text-sm text-gray-500">
                              {{item.nivel_conformidade ? `${item.nivel_conformidade}%` : '0%'}}
                            </div>
                          </div>
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="text-sm text-gray-500">
                              {{ item.dataFinalizada | moment("DD/MM/YYYY")}}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <pagination v-model="page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
              </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            fornecedor: null,
            fornecedores: [],
            list: [],
            total: 0,
            page: 1,
            perPage: 20,
        }
    },
    methods: {
        async start(){
            const listReq = await this.$http.post(`/v1/diagnosticos/listRelatorioFornecedor`,{ fornecedor: this.fornecedor });
            if(!listReq.data || !listReq.data.data.length) {
              this.list = [];
              this.total = 0;
              return this.$vToastify.error("Nenhum diagnóstico encontrado");
            }
            this.list = listReq.data.data;
            this.total = listReq.data.total;
        },

        async setPage(page) {
            this.page = page;
            const skip = (page - 1) * this.perPage;
            const limit = this.perPage;
            const listReq = await this.$http.post(`/v1/diagnosticos/listRelatorioFornecedor`, { fornecedor: this.fornecedor, skip, limit });
            this.list = listReq.data.data;
            this.total = listReq.data.total;
        },
    },
    async beforeMount() {
        const listfornecedores = await this.$http.post(`/v1/fornecedores/list`);
        this.fornecedores = listfornecedores.data.data;

        this.start();
    }
}
</script>